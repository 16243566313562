<template>
  <div class="my-4 container px-2">
    <div class="is-flex is-flex-direction-column is-align-items-center">
      <b-button type="is-primary my-2">
        Primary
      </b-button>
      <b-button type="is-info my-2">
        Info
      </b-button>
      <b-button type="is-success my-2">
        Success
      </b-button>
      <b-button type="is-warning my-2">
        Warning
      </b-button>
      <b-button type="is-danger my-2">
        Danger
      </b-button>
      <b-button type="is-danger is-outlined my-2">
        Danger Outlined
      </b-button>
      <b-button type="is-text my-2">
        Text
      </b-button>
      <b-button type="is-text has-text-danger my-2">
        Danger Text
      </b-button>
      <b-button type="is-ghost my-2">
        Ghost
      </b-button>
      <b-button type="is-danger is-ghost my-2">
        Danger Ghost
      </b-button>
      <b-button type="is-inverted my-2">
        Inverted
      </b-button>
      <b-button type="is-warning is-inverted my-2">
        Warning Inverted
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonTest"
};
</script>
